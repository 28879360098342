import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import { graphql } from 'gatsby';
import SectionEntry from '../components/section-entry';
import Header from '../components/header';
import PageWrapper from '../components/common/page-wrapper';
import EmailListSignUp from '../components/EmailListSignUp';

/*
TODO:
Ideas for iterations
- add dark mode
- add your own content
-  create categories so people can easily find the kind of posts they want
*/

const IndexPage = ({ data }) => {
  const posts =
    data.allMarkdownRemark.edges.map((edge) => ({
      ...edge.node.frontmatter,
      tags: edge.node.frontmatter.tags.split(','),
    })) || [];

  return (
    <Layout>
      <SEO
        title="Home"
        keywords={[
          `Babs Craig`,
          `Learn JavaScript`,
          `Babasanya Craig`,
          `Front End Development`,
          `React`,
          `JavaScript`,
          `Developer`,
        ]}
      />
      <Header headerTitle={data.site.siteMetadata.title} />
      <PageWrapper>
        {posts.map((post) => (
          <SectionEntry
            key={post.index}
            title={post.title}
            titleLink={post.path}
            renderBody={() => <p>{post.description}</p>}
            tags={post.tags}
          />
        ))}
        <EmailListSignUp />
      </PageWrapper>
    </Layout>
  );
};

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }

    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___index] }
      filter: { frontmatter: { type: { eq: "post" }, published: { eq: true } } }
    ) {
      edges {
        node {
          frontmatter {
            path
            title
            description
            tags
            index
          }
        }
      }
    }
  }
`;

export default IndexPage;
